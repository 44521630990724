import React from 'react'
import Img from 'gatsby-image'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

import CDBMaisLimiteJSON from '../../assets/data/CDBMaisLimite.json'

import { Section, Carousel, Circle } from './style'

import usePageQuery from './../../pageQuery'

type AumentarLimiteComCDBProps = {
  title: string;
  description: string;
  image: string;
  altImage: string;
}

const AumentarLimiteComCDB = () => {
  const width = useWidth()
  const data = usePageQuery()

  return (
    <Section className='bg-gray'>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <h3 className='fs-16 fs-md-24 fs-lg-32 fs-xl-40 lh-20 lh-md-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 mb-0 mb-md-2 mb-lg-4 mb-xl-5'>
            Como aumentar o limite com o CDB Mais Limite?
          </h3>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 4 }}
              lg={{ items: 4 }}
              xl={{ items: 4 }}
            >
              {
                CDBMaisLimiteJSON.map((item: AumentarLimiteComCDBProps, index: number) => (
                  <Carousel className='px-md-1' key={index}>
                    <div className='content-text'>
                      <p className='fs-24 fs-md-16 fs-lg-20 lh-30 lh-md-20 lh-lg-25 fw-600 text-grayscale--500 text-md-center px-md-2'>
                        <Circle className={`${width >= 768 ? 'bg-orange text-white d-flex justify-content-center align-items-center mb-3' : ''}`}>
                          {index + 1}
                        </Circle> <span className='d-md-none'>-</span> {item.title}
                      </p>
                      <p className='fs-14 fs-lg-16 lh-md-16 lh-lg-20 text-grayscale--400 text-md-center description' dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                    <Img fluid={data[item.image].fluid} className='img-carousel' alt={item.altImage} />
                  </Carousel>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default AumentarLimiteComCDB
