import React from 'react'
import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

// Components
import Icon from 'src/components/UI/MarkdownIcon'

// Style
import { Section, Blog, ContentPost } from './style'

// Assets
import ConfiraMaisConteudosJSON from '../../assets/data/ConfiraMaisConteudos.json'

type ItensProps = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

const DicasParaInvestirMelhor = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const data = usePageQuery()
  return (
    <Section className='bg-white'>
      <div className='container'>
        <div className='row justify-content-center'>
          <h3 className='fs-24 fs-lg-32 fs-xl-40 fw-600 lh-30 lh-lg-40 lh-xl-50 text-center mb-5 mb-md-4'>
            <span className='d-lg-block'>Confira mais conteúdos sobre limite de</span> crédito no Inter!
          </h3>
        </div>
        <div className='row'>
          { ConfiraMaisConteudosJSON.map((item: ItensProps, index: number) => (
            <Blog key={index} className='col-12 col-md-4 p-card mt-md-3'>
              <a
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_8',
                    element_action: 'click button',
                    element_name: 'Leia mais' + ' - ' + item.title,
                    section_name: 'Confira mais conteúdos sobre limite de crédito no Inter!',
                    redirect_url: item.link,
                  })
                }}
                data-blog={item.title}
                href={item.link}
                title={`Leia mais em ${item.link}`}
              >
                <article className='col-12 px-0'>
                  <Img fluid={data[item.image].fluid} alt={item.alt} />
                  <ContentPost className='col-12 pt-2 rounded-5 mt-n5 bg-white mb-2 px-3'>
                    <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 mr-3 px-1 primary-tag'>{item.primaryTag}</p>
                    {item.secundaryTag ? <p className='fs-12 lh-15 fw-600 text-black bg-gray-400 rounded-1 mb-1 px-2 secundary-tag d-none d-lg-inline'>{item.secundaryTag}</p> : ''}
                    <p className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-xl-2 title-post text-grayscale--500'>
                      {item.title}
                      <span className='fs-12 lh-15 fw-700 d-block text-grayscale--300 mt-2'>{item.data}</span>
                    </p>
                    <p className='fs-14 fs-lg-16 lh-19 lh-md-19 mb-2 fw-400 d-none d-lg-block mt-3 text-grayscale--400'>
                      {item.description}
                    </p>
                    <span className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-lg-block mt-xl-3'>Leia mais <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' /></span>
                  </ContentPost>
                </article>
              </a>
            </Blog>
              ))
            }
        </div>
      </div>
    </Section>
  )
}

export default DicasParaInvestirMelhor
