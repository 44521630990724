import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Sora';
  }
`

export const Section = styled.div`

`
