import React, { useState, useLayoutEffect } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Layout from 'src/layouts/BaseLayout'
import FAQ from './sections/faq/_index'

// sections
import Hero from './sections/hero/_index'
import PoupancaMaisLimite from './sections/poupanca-mais-limite/_index'
import ConseguirLimiteComPoupanca from './sections/conseguir-limite-com-poupanca/_index'
import CDBMaisLimite from './sections/cdb-mais-limite/_index'
import AumentarLimiteComCDB from './sections/aumentar-limite-com-cdb/_index'
import PedirAumentoDeLimite from './sections/pedir-aumento-de-limite/_index'
import DicasParaAumentarSeuLimite from './sections/dicas-para-aumentar-seu-limite/_index'
import ConfiraMaisConteudos from './sections/confira-mais-conteudos/_index'

// Hooks
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

// Components
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

// PageContext
import pageContext from './pageContext.json'

// images
import QrCode from './assets/images/qrcode_aumentarlimite.jpeg'

// Styles
import { Wrapper } from './style'

const WIDTH_MD = 768

const CotacaoDolar = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const domReady = useDomReady()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isStore={true}
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Abra sua conta digital e comece a acumular pontos com o cartão de crédito Inter'
        subtitle='Abra a câmera do celular e aponte para o <span class="fw-600">QR Code</span> abaixo para baixar o app.'
        qrCode={QrCode}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Layout pageContext={pageContext}>
      {hubModal}
      <Wrapper>
        <Hero
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
        <PoupancaMaisLimite
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
        <ConseguirLimiteComPoupanca />
        <CDBMaisLimite
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
        <AumentarLimiteComCDB />
        <PedirAumentoDeLimite
          sendDatalayerEvent={sendDatalayerEvent}
          setIsOpen={setIsOpen}
          isMobile={isMobile}
        />
        <DicasParaAumentarSeuLimite />
        <ConfiraMaisConteudos
          sendDatalayerEvent={sendDatalayerEvent}
        />
        <FAQ faq={pageContext.structuredData.faq} />
      </Wrapper>
    </Layout>
  )
}

export default CotacaoDolar
