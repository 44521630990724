import React from 'react'

import { Section, Link, Button } from './style'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  isMobile: boolean;
}

const Hero = ({ setIsOpen, isMobile, sendDatalayerEvent }: IModalDataLayerProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Aumentar meu Limite',
      section_name: 'Como aumentar o limite do cartão?',
    })
  }

  const oneLink = 'https://bancointer.go.link/cartoes?adj_t=9io9bkj&adj_campaign=lp_aumentodelimite&adj_adgroup=cartao&adj_creative=ctaaumentarlimite&adj_fallback=https%3A%2F%2Finter.co%3Futm_source%3Dlp_aumento_poupanca%26utm_medium%3Dsite%26utm_campaign%3Dlp_aumento&adj_redirect_macos=https%3A%2F%2Finter.co%3Futm_source%3Dlp_aumento_poupanca%26utm_medium%3Dsite%26utm_campaign%3Dlp_aumento'

  return (
    <Section className='d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 pr-md-0'>
            <h1 className='fs-24 fs-lg-32 fs-xl-40 lh-32 lh-lg-42 lh-xl-50 fw-600 text-grayscale--500'>
              <span className='d-xl-block'>Como aumentar o</span> limite do cartão?
            </h1>
            <h2 className='fs-20 fs-lg-24 lh-25 lh-lg-30 fw-600 text-orange--extra mt-3'>
              <span className='d-xl-block'>Tenha autonomia para aumentar o</span> <span className='d-xl-block'>limite do seu cartão sempre que</span> quiser!
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 fw-400 text-grayscale--500 mt-3'>
              Aplique na Poupança Mais Limite Extra ou no CDB Mais Limite e todo valor vira limite no seu cartão de crédito.
            </p>
            {
              isMobile ? (
                <Link
                  className='mt-4'
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_1',
                      element_action: 'click button',
                      element_name: 'Aumentar meu Limite',
                      section_name: 'Como aumentar o limite do cartão?',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Aumentar meu Limite
                </Link>
              ) : (
                <Button onClick={handleButtonClick} title='Aumentar meu Limite' className='fs-14 lh-17 fw-600 rounded-2 border-0 text-none mt-md-4'>
                  Aumentar meu Limite
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
