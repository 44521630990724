import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0 67px 0;

  @media ${device.tablet} { 
    height: 589px;
    padding: 22px 0 20px 0;
  }

  @media ${device.desktopLG} {
    height: 727px;
    padding: 64px 0 30px 0;
  }

  @media ${device.desktopXL} {
    height: 820px;
    padding: 96px 0 73px 0;
  }
`

export const ContentPost = styled.div`
  @media ${device.tablet} { 
    top: 25px;
    height: 220px;
  }
  @media ${device.desktopLG} { 
    top: 0;
    height: 220px;
  }
  @media ${device.desktopXL} { 
    top: -25px;
    height: 220px;
  }
`

export const Blog = styled.div`
  @media ${device.tablet} { 
    height: 300px;
  }
  .primary-tag, .secundary-tag {
    display: inline;
  }

  article {
    display: grid;
    min-height: 430px;
    @media ${device.tablet} { 
      min-height: 517px;
    }
    
    img {
      @media ${device.tablet} { 
        height: 245px!important;
      }
    }
    .gatsby-image-wrapper {
      @media ${device.desktopXL} { 
        max-width: 460px!important;
      }
      @media ${device.desktopXXL} { 
        max-width: 490px!important;
        max-height: 280px!important;
      }
    }
  }

  .title-post {
    font-family: 'Sora';
    @media ${device.tablet} { 
      letter-spacing: 0;
      height: 160px;
    }
    @media ${device.desktopLG} { 
      letter-spacing: 1;
      height: auto;
    }
  }
`
