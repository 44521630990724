import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 28px 0 40px 0;

  @media ${device.tablet}{
    padding: 40px 0 57px 0;
  }

  @media ${device.desktopLG}{
    padding: 64px 0 65px 0;
  }

  @media ${device.desktopXL}{
    padding: 96px 0 97px 0;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 48px;
  background: ${orange.extra};
  color: ${white};
  border-radius: 8px;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    opacity: 0.9;
    color: rgb(255, 255, 255);
  }

  @media ${device.desktopLG}{
    margin-top: 33px;
  }
  @media ${device.desktopXL}{
    margin-top: 27px;
  }
`
export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${orange.extra};
  color: ${white};
  width: 100%;
  height: 48px;

  &:focus { 
    outline: none; 
  } 

  &:hover {
    opacity: 0.9;
    color: rgb(255, 255, 255);
  }
  
  @media ${device.tablet} {
    width: 336px;
    margin-top: 10px;
  }

  @media ${device.desktopLG} {
    width: 456px;
    margin-top: 12px;
  }

  @media ${device.desktopXL} {
    width: 457px;
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    @media ${device.tablet} {
      width: 335px;
    }
    @media ${device.desktopLG} {
      width: 376px;
    }
    @media ${device.desktopXL} {
      width: 446px;
    }
  }
`
