import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const FAQSection = styled.section`
  padding: 40px 0 48px 0;

  @media ${device.desktopLG} {
    padding: 64px 0 93px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0 20px 0;
  }

  .search-input {
    background: ${white};
  }
`
