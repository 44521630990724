import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section, Tips } from './style'

const DicasMaisLimite = () => {
  return (
    <Section className='bg-orange--extra'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-4 col-lg-4 col-xl-5'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-white fw-600'>Dicas para aumentar seu limite</h2>
            <p className='fs-18 lh-20 text-white mt-3 mt-md-4'>
              Quanto mais você usa o Super App, mais nos ajuda a te conhecer e entender como você se organiza para poder aumentar o seu limite.
            </p>
          </div>
          <div className='col-12 col-md-8 col-lg-7 col-xl-6 offset-lg-1 mt-3 mt-lg-0'>
            <Tips className='row'>
              <div className='col-6 col-md-4 d-flex align-items-center px-md-1'>
                <div className='tip pt-4 pt-md-3 pt-xl-4'>
                  <div className='d-flex justify-content-center justify-content-md-start pl-md-3 pl-xl-4'><OrangeIcon size='MD' color='#FF7A00' icon='savings' /></div>
                  <div className='px-4 px-md-3 px-xl-4'>
                    <h3 className='fs-16 fs-xl-18 lh-20 lh-xl-25 text-grayscale--400 fw-600 text-center text-md-left mt-3 mt-xl-4'>Pague sua fatura sempre em dia</h3>
                  </div>
                </div>
              </div>
              <div className='col-6 col-md-4 d-flex align-items-center px-md-1'>
                <div className='tip pt-4 pt-md-3 pt-xl-4'>
                  <div className='d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pl-md-3 pl-xl-4'><OrangeIcon size='MD' color='#FF7A00' icon='investments' /></div>
                  <div className='px-4 px-md-3 px-xl-4'>
                    <h3 className='fs-16 fs-xl-18 lh-20 lh-xl-25 text-grayscale--400 fw-600 text-center text-md-left mt-3 mt-xl-4'>Invista pelo Inter Invest</h3>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 mt-3 mt-md-0 d-flex align-items-center px-md-1'>
                <div className='tip pt-4 pt-md-3 pt-xl-4'>
                  <div className='d-flex justify-content-center justify-content-md-start pl-md-3 pl-xl-4'><OrangeIcon size='MD' color='#FF7A00' icon='digital-account' /></div>
                  <div className='px-4 px-md-3 px-xl-4'>
                    <h3 className='fs-16 fs-xl-18 lh-20 lh-xl-25 text-grayscale--400 fw-600 text-center text-md-left mt-3 mt-xl-4'>Movimente sua Conta Digital do Inter</h3>
                  </div>
                </div>
              </div>
            </Tips>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default DicasMaisLimite
