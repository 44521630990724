import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      cdbMaisLimite: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-cdb-mais-limite-tela-app/" }}) {
        fluid(maxWidth: 447, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      poupancaMaisLimite: imageSharp(fluid: {originalName: { regex: "/como-aumentar-limite-poupanca-mais-limite/" }}) {
        fluid(maxWidth: 584, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      acesseSuperApp: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-cdb-mais-limite-escolha-o-valor/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      escolhaOValor: imageSharp(fluid: {originalName: { regex: "/como-aumentar-limite-do-cdb-mais-limite/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      soAproveitar: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-cdb-mais-limite-so-aproveitar/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      resgateQuandoQuiser: imageSharp(fluid: {originalName: { regex: "/como-aumentar-limite-cdb-mais-limite/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      poupancaAcesseSuperApp: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-poupanca-mais-limite-acesse-super-app/" }}) {
        fluid(maxWidth: 245, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      poupancaEcolhaOValor: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-poupanca-mais-limite-escolha-o-valor/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      poupancaSoAproveitar: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-poupanca-mais-limite-aproveitar/" }}) {
        fluid(maxWidth: 246, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      poupancaResgateQuandoQuiser: imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-resgate-poupanca-mais-limite/" }}) {
        fluid(maxWidth: 255, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pedirAumentoDeLimite: imageSharp(fluid: {originalName: { regex: "/pedir-aumento-de-limite/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      comoUsarCDBMaisLimite : imageSharp(fluid: {originalName: { regex: "/como-usar-cdb-mais-limite/" }}) {
        fluid(maxWidth: 361, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      comoAumentarLimiteCartao : imageSharp(fluid: {originalName: { regex: "/poupanca-aumentar-limite-cartao/" }}) {
        fluid(maxWidth: 361, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      aumentarLimiteCartao : imageSharp(fluid: {originalName: { regex: "/aumento-de-limite-limite-cartao/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
