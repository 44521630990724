import React from 'react'
import Img from 'gatsby-image'

import { Section, Link, Button, ImgSize } from './style'
import usePageQuery from '../../pageQuery'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
  setIsOpen: Function;
  isMobile: boolean;
}
const PoupancaMaisLimite = ({ setIsOpen, isMobile, sendDatalayerEvent }: IModalDataLayerProps) => {
  const data = usePageQuery()

  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_4',
      element_action: 'click button',
      element_name: 'Turbinar Limite',
      section_name: 'Poupança Mais Limite',
    })
  }

  const oneLink = 'https://bancointer.go.link/meiosPagamento/rota/POUPANCA_MAIS_LIMITE_ONBOARDING?adj_t=9io9bkj&adj_campaign=lp_aumentodelimite&adj_adgroup=cartao&adj_creative=ctapoupanca&adj_fallback=https%3A%2F%2Finter.co%3Futm_source%3Dlp_aumento_poupanca%26utm_medium%3Dsite%26utm_campaign%3Dlp_aumento&adj_redirect_macos=https%3A%2F%2Finter.co%3Futm_source%3Dlp_aumento_poupanca%26utm_medium%3Dsite%26utm_campaign%3Dlp_aumento'

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-center'>
          <ImgSize className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.poupancaMaisLimite.fluid} alt='Tela de celular mostrando a Poupança Limite no Superapp' />
          </ImgSize>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-42 lh-xl-50 fw-600 text-grayscale--500 mt-4 mt-md-0'>
              Poupança Mais Limite Extra
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 fw-400 text-grayscale--500 mt-3'>
              Guarde a partir de R$ 50 na <span className='fw-700'>Poupança Mais Limite</span> e converta o valor aplicado + <span className='fw-700'>10% extra</span> em limite no seu cartão de crédito.
            </p>
            {
              isMobile ? (
                <Link
                  className='mt-4'
                  href={oneLink}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_4',
                      element_action: 'click button',
                      element_name: 'Turbinar Limite',
                      section_name: 'Poupança Mais Limite',
                      redirect_url: { oneLink },
                    })
                  }}
                >
                  Turbinar Limite
                </Link>
              ) : (
                <Button onClick={handleButtonClick} title='Turbinar Limite' className='fs-14 lh-17 fw-600 rounded-2 border-0 text-none mt-md-4'>
                  Turbinar Limite
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default PoupancaMaisLimite
