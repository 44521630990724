import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0 56px 0;
  
  @media ${device.tablet} {
    padding: 44px 0 41px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 101px 0 97px 0;
  }

  .react-multi-carousel-track  {
    position: relative;
    padding: 20px 0 0 0!important;
  }
  .react-multi-carousel-list {
    padding: 0 0 60px 0;

    @media ${device.tablet} {
      padding: 0 0 0 0;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background: #FF7A00;
    }
  }
  .react-multi-carousel-dot {
    button {
      background: #FF7A00;
      opacity: 0.5;
    }
  }
`

export const Carousel = styled.div`
  .img-carousel {
    width: 169px;
    margin: 0 auto;

    @media ${device.tablet} {
      width: 154px;
    }
    @media ${device.desktopLG} {
      width: 214px;
    }
    @media ${device.desktopXL} {
      width: 233px;
    }
  }
  .content-text {
    @media ${device.tablet} {
      height: 238px;
    }
    @media ${device.desktopLG} {
      height: 255px;
    }
    @media ${device.desktopXL} {
      height: 212px;
    }
  }
  .description {
    @media ${device.tablet} {
      height: 100px;
    }
  }
`

export const Circle = styled.div`
  float: left;
  @media ${device.tablet} {
    border-radius: 28px;
    width: 32px;
    height: 32px;
    margin: 0 auto;
    float: none;
  }
  @media ${device.desktopLG} {
    width: 44px;
    height: 44px;
  }
`
