import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding: 40px 0 40px 0;

  @media ${device.desktopLG} {
    padding: 64px 0 22px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0 96px 0;
  }
`

export const Tips = styled.div`
  .tip {
    background: ${grayscale['100']};
    border-radius: 10px;
    height: 155px;
    
    @media ${device.desktopLG} {
      height: 134px;
    }

    @media ${device.desktopXL} {
      height: 218px;
    }
  }
  div:last-child {
    .tip {
      height: 115px;

      @media ${device.tablet} {
        height: 155px;
      }

      @media ${device.desktopLG} {
        height: 134px;
      }

      @media ${device.desktopXL} {
        height: 218px;
      }
    }
  }
`
